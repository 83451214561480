import React from 'react'
import { graphql } from 'gatsby'

//STYLES
import '../styles/dynamic-pages-css/product-delivery-process.css'
import '../styles/dynamic-pages-css/common-styles.css'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import ProductDeliveryProcessContent from '../components/productDeliveryProcessContent'
import InterestedBlock from '../components/interestedBlock'
import { useTranslation } from 'react-i18next'

const ProductDeliveryProcess = ({ data }) => {
  let md = data.markdownRemark.frontmatter
  const { t } = useTranslation()

  let productDeliveryProcessContent = md.sectionArray.map((item, i) => (
    <ProductDeliveryProcessContent
      key={i}
      icon={item.icon}
      blockTitle={t(`productDeliveryProcessBlockTitle.${i}`, {
        returnObjects: true,
      })}
      blockText={t(`productDeliveryProcessBlockText.${i}`, {
        returnObjects: true,
      })}
    />
  ))

  return (
    <div id="product-delivery">
      <BackgroundImage
        className="header-img"
        fluid={md.headerImage.childImageSharp.fluid}
        alt="Index header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{t('productDeliveryProcessTitle')}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="first-grid">
          <div className="innovation-text">
            <p className="blue-text">
              {t('productDeliveryProcessTitle').toUpperCase()}
            </p>
            <h2 className="section-title">
              {t('productDeliveryProcessSubTitle')}
            </h2>
            <p className="section-ingress">
              {t('productDeliveryProcessMainIngress')}
            </p>
            <p className="section-text">
              {t('productDeliveryProcessMainText')}
            </p>
          </div>
          <div className="innovation-img">
            <Img
              className="working-image gatsby-image"
              fluid={md.mainImage.childImageSharp.fluid}
              alt="workingspace"
            />
          </div>
        </div>
        <div className="video-section">
          <div className="video">
            <iframe
              src={md.video}
              allowtransparency="true"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              title="video"
            ></iframe>
          </div>
          <div className="video-text">
            <h2 style={{ fontSize: '24px' }}>{t('videoTitle')}</h2>
            <p>{md.videoText}</p>
            <button className="blue-border-btn">
              <a href={md.videoLink}>{md.linkText}</a>
            </button>
          </div>
        </div>
        <div className="main-section">
          <div className="first-section">
            <p className="blue-text">
              {t('productDeliveryProcessSectionTitle').toUpperCase()}
            </p>
            <h2 className="section-title">
              {t('productDeliveryProcessSectionH1')}
            </h2>
            <p className="section-text">{md.sectionText}</p>
          </div>
          <div className="second-section" style={{ maxWidth: '800px' }}>
            {productDeliveryProcessContent}
          </div>
        </div>
        <InterestedBlock
          interestedBlueText={md.interestedBlueText}
          interestedTitle={md.interestedTitle}
          interestedText={md.interestedText}
        />
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        blueText
        mainH1
        mainIngress
        mainText
        sectionBlueText
        sectionH1
        sectionText
        interestedTitle
        interestedText
        interestedBlueText
        lang
        videoText
        videoTextH2
        video
        videoLink
        linkText
        sectionArray {
          blockTitle
          blockText
          icon
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ProductDeliveryProcess
