import React from 'react'
import '../styles/template1.css'

const ProductDeliveryProcessContent = props => {
  return (
    <div className="information-block">
      <div
        style={{
          backgroundColor: 'rgba(22,123,250,.3)',
          borderRadius: '10px',
          height: '50px',
          width: '50px',
          fontFamily: 'ElegantIcons',
          textAlign: 'center',
          color: '#167bfa',
          fontSize: '30px',
          lineHeight: '1.70',
        }}
      >
        {props.icon}
      </div>
      <h4 className="block-title h4">{props.blockTitle}</h4>
      <p className="block-text">{props.blockText}</p>
    </div>
  )
}

export default ProductDeliveryProcessContent
